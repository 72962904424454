//
// reboot.scss
//

html {
    position: relative;
    min-height: 100%;
}


// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
    body {
        cursor: pointer;
    }
}

body {
    overflow-x: hidden;
}

button,
a {
    outline: none !important;
}



// Forms

label {
    font-weight: $font-weight-semibold;
}

// Address
address.address-lg {
    line-height: 24px;
}

b,
strong {
    font-weight: $font-weight-bold;
}